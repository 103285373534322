<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-09-07 15:26:03
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2023-10-26 14:07:22
 * @Description: 
-->
<template>
	<div class="dashboard">
		<div class="dashboard-wrapper">
			<div class="search-box">
				<a-input
					class="input"
					v-model.trim="keyword"
					placeholder="请输入店铺名称"
					type="text"
					allow-clear
					@change="onChange"
					@keydown.enter="onSearch" />
				<a-button type="primary" @click="onSearch">搜索</a-button>
			</div>
			<div class="store-list">
				<div
					class="store-item"
					v-for="item in storeList"
					:key="item.id">
					<div class="info">
						<img
							class="avatar"
							:src="
								item.logo
									? item.logo
									: '../../assets/store-avatar.png'
							" />
						<div class="detail">
							<div class="name">{{ item.title }}</div>
							<div class="id">店铺ID: {{ item.id }}</div>
						</div>
					</div>
					<div class="bottom" @click="onChangeStore(item.id)">
						进入门店
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash'
import storage from 'store'
import store from '@/store'
import { selectShop } from '@/api/Official'
import { USER_INFO } from '@/utils/request'

export default {
	name: 'StoreList',
	data() {
		return {
			originList: [],
			storeList: [],
			keyword: ''
		}
	},
	mounted() {
		this.storeList = storage.get('shopList')
		this.originList = [...this.storeList]
	},
	methods: {
		/**
		 * @description: 搜索
		 * @return {*}
		 */
		onSearch() {
			this.storeList = this.storeList.filter(item =>
				item.title.includes(this.keyword)
			)
		},
		/**
		 * @description: 监听输入框是否清空
		 * @return {*}
		 */
		onChange(ev) {
			if (!ev.data && !this.keyword) {
				this.storeList = [...this.originList]
			}
		},
		/**
		 * @description: 点击切换门店
		 * @return {*}
		 */
		async onChangeStore(id) {
			const { shop } = storage.get(USER_INFO)
			// 同一门店无需切换
			if (shop.id === id) {
				this.$router.push({ name: 'Index' })
				return
			}
			// 调用选择店铺接口
			const { code, data } = await selectShop({ shop_id: id })
			if (code === 0) {
				// 存储登录的信息数据
				storage.set(USER_INFO, { shop: _.cloneDeep(data.shop) })
				// 设置状态
				const initInfo = {
					auth_type: 'work_admin',
					shop: _.cloneDeep(data.shop),
					user: {
						phone: shop.phone
					}
				}
				store.commit('user/SET_INFO', initInfo)
				// 提示完成登录，进行页面跳转
				this.$message
					.success('登录成功')
					.then(this.$router.push({ name: 'Index' }))
			}
		}
	}
}
</script>

<style lang="less" scoped>
::v-deep .ant-input {
	height: 40px;
}
.dashboard {
	background-color: #f0f2f5;
	padding: 24px;
	box-sizing: border-box;
}

.dashboard-wrapper {
	width: 100%;
	min-height: calc(100vh - 114px);
	background-color: #fff;
	border-radius: 4px;
	padding: 25px 22px;
}

.search-box {
	display: flex;
	justify-content: flex-start;
	align-content: center;
	.input {
		width: 250px;
		height: 40px;
		line-height: 40px;
		background: #ffffff;
		border-radius: 2px;
		font-size: 16px;
		color: #333;
	}
	button {
		margin-left: 15px;
		width: 96px;
		height: 40px;
		background: #e63e30;
		border-radius: 2px;
	}
}
.store-list {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: 25px;
	.store-item {
		margin: 0 18px 18px 0;
		width: 320px;
		height: 145px;
		background: #fff;
		border: 1px solid #e6e6e6;
		box-shadow: 0 2px 8px 1px #f2f2f2;
		border-radius: 4px;
		.info {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 0 14px;
			height: 104px;
			box-sizing: border-box;
			border-bottom: 1px solid #e8e8e8;
			.avatar {
				margin-right: 12px;
				width: 65px;
				height: 65px;
				border-radius: 50%;
				border: 1px solid #e6e6e6;
			}
			.detail {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
			}
			.name {
				display: -webkit-box;
				line-clamp: 2;
				text-overflow: ellipsis;
				text-overflow: -o-ellipsis-lastline;
				font-size: 20px;
				font-weight: 900;
				color: #333;
				overflow: hidden;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
			.id {
				margin-top: 10px;
				height: 16px;
				font-size: 14px;
				color: #666;
				line-height: 16px;
			}
		}
		.bottom {
			height: 40px;
			line-height: 40px;
			text-align: center;
			font-size: 14px;
			color: #e63e30;
			cursor: pointer;
		}
	}
}
</style>
